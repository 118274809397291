import React from 'react';
import './_loader-spinner.css';

export const LoaderSpinner = () => {
  return (
    <span className="loader-spinner-outer">
      <span className="loader-spinner"></span>
    </span>
  );
};
