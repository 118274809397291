import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { mainLinks } from 'config';
import { useTrail, animated } from 'react-spring';
import { legalLinks, staticData } from 'config';

import { isTouchDevice } from 'utils';
// import { LanguagePicker } from './LangaugePicker';
// import Mapbox from 'components/Mapbox';

// import { DelayLinkTransition } from 'router/DelayLink';

import NavButton from './NavButton';
import NavElement from './NavElement';
import SimpleLink from 'ui/atoms/SimpleLink';
import AwesomeIcon from 'ui/atoms/AwesomeIcon';
import Media from 'ui/atoms/Media';

import MakeAWishLogo from 'assets/logo-white.png';
import { useIsMobile } from 'hooks';

const locations = [
  {
    lat: 46.6381298,
    lng: 14.3233175,
    zoom: 17,
  },
];

const isTouch = isTouchDevice();

let mouseEnterTimeout;

const Nav = () => {
  const [lastLocation, setLastLocation] = useState('');
  const [top, setTop] = useState();
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState();
  const [activeImage, setActiveImage] = useState('');
  const [activeSub, setActiveSub] = useState('');

  const isMobile = useIsMobile();

  const { pathname } = useLocation();

  const { companyName } = staticData;

  useEffect(() => {
    setActiveImage(
      mainLinks.findIndex(({ link }) => pathname.search(link) !== -1),
    );
    if (lastLocation && lastLocation !== pathname) {
      setOpen(false);
      setTransition(true);
      setTimeout(() => {
        setTransition(false);
      }, 900);
      // setTimeout(() => {
      //   setTransition(false);
      // }, 1000);
    }
    setLastLocation(pathname);
  }, [pathname, lastLocation]);

  useEffect(() => {
    // const body = document.getElementsByTagName('body')[0];
    // const root = document.getElementById('root');
    // setTop(window.pageYOffset);

    if (open) {
      // body.style.height = '0vh';
      // body.style.overflow = 'hidden';

      setTimeout(() => {
        // root.style.height = '100vh';
        // root.style.overflow = 'hidden';
      }, 500);
    }
    if (!open) {
      // body.style.height = '100%';
      // body.style.overflow = 'auto';
      // root.style.height = 'auto';
      // root.style.overflow = 'auto';
      // window.scrollTo(0, top);
    }
  }, [open]);

  const toggleOpen = open ? 'open' : '';

  const transitions = useTrail(mainLinks.length, {
    config: { mass: 1, tension: 500, friction: 34 },
    delay: open ? 200 : 0,
    opacity: open ? 1 : 0,
    y: open ? 0 : 20,
    from: { opacity: 0, y: 20 },
  });

  return (
    <>
      <nav
        style={{ padding: 0 }}
        className={`fw ${
          isTouch ? 'touch-support' : ''
        } row justify-space-between flex-align-stretch ${toggleOpen} ${
          transition ? 'anim' : ''
        }`}
      >
        {/* <TopBar /> */}

        <div className="fw nav__content p-5">
          <div className="closeButton pointer " onClick={() => setOpen(false)}>
            <AwesomeIcon size="2x" icon="close" prefix="fac" />
          </div>
          <div className="row fw fh flex-align-space-between">
            <div className="pt-5 pl-2 pr-2 col-12">
              <div className="row justify-center fw logo-container">
                <Media src={MakeAWishLogo.src} srcSet={MakeAWishLogo.srcSet} />
              </div>
              <div className="fw pt-10">
                {transitions.map(({ y, ...rest }, i) => {
                  return (
                    <animated.div
                      key={i}
                      style={{
                        ...rest,
                        transform: y.to((y) => `translate3d(0,${y}px,0)`),
                      }}
                    >
                      <NavElement
                        {...mainLinks[i]}
                        onClick={(e) => {
                          if (isMobile) {
                            setOpen(false);
                          }
                        }}
                        activeSub={activeSub === i}
                      />
                    </animated.div>
                  );
                })}
              </div>
            </div>
            <div className="col-12 nav-notes align-center">
              <p className="uppercase">© {companyName}</p>
              {legalLinks.map((data, i) => (
                <p key={i}>
                  <a href={data.link}>{data.text}</a>
                </p>
              ))}

              {/* <div className="white socials pt-2 pb-10 pb-md-0">
                <AwesomeIcon icon="linkedin" prefix="fab" size="lg" />
                <AwesomeIcon icon="facebook-square" prefix="fab" size="lg" />
                <AwesomeIcon icon="instagram" prefix="fab" size="lg" />
                <AwesomeIcon icon="youtube" prefix="fab" size="lg" />
              </div> */}
            </div>
          </div>
        </div>
      </nav>

      <NavButton className={`${toggleOpen}`} onClick={() => setOpen(!open)} />
      {/* <Link
        aria-label="Home"
        to="/"
        className="header-logo row flex-align-center"
      >
        <img src="/logo.png" alt="" />
      </Link> */}
    </>
  );
};

const TopBar = () => {
  const { email, tel } = staticData;

  return (
    <div className="top-bar fw row flex-align-center justify-space-between show-on-mobile">
      <SimpleLink
        to="/"
        className="column logo-link justify-center flex-aign-center"
      >
        {/* <img
          loading="lazy"
          className="logo-icon"
          src="logo.png"
          alt="some logo"
        /> */}
      </SimpleLink>
      <div className="contact-info row justify-space-between">
        <div>
          <a className="simple-hover mr-4" href={tel.href}>
            <AwesomeIcon className="mr-1" icon="phone" />
            <span>{tel.text}</span>
          </a>
          <a
            className="simple-hover mr-4"
            href={email.href}
            target="_blank"
            rel="noreferrer noopener"
          >
            <AwesomeIcon className="mr-1" icon="envelope" />
            <span>{email.text}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Nav;
